import * as THREE from "three"
import { useGLTF } from '@react-three/drei'

import React, { useEffect, useRef, useState } from "react"
import { useFrame, useThree } from '@react-three/fiber'


var url = './3DModels/Happy1.glb';


function GirlCharacter({ children, ...props }) {


    function moveJoint(mouse, joint, degreeLimit = 20) {
        let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit)
        joint.rotation.xD = THREE.MathUtils.lerp(joint.rotation.xD || 0, degrees.y, 0.1)
        joint.rotation.yD = THREE.MathUtils.lerp(joint.rotation.yD || 0, degrees.x, 0.1)
        joint.rotation.x = THREE.Math.degToRad(joint.rotation.xD)
        joint.rotation.y = THREE.Math.degToRad(joint.rotation.yD)
    }

    function getMouseDegrees(x, y, degreeLimit) {
        let dx = 0,
            dy = 0,
            xdiff,
            xPercentage,
            ydiff,
            yPercentage

        let w = { x: window.innerWidth, y: window.innerHeight / 2 }

        //the canvas is half width in desktop, full width in mobile and alway half helgt
        if (w.x > 960)
            w.x /= 2;

        // Left (Rotates neck left between 0 and -degreeLimit)
        // 1. If cursor is in the left half of screen
        if (x <= w.x / 2) {
            // 2. Get the difference between middle of screen and cursor position
            xdiff = w.x / 2 - x
            // 3. Find the percentage of that difference (percentage toward edge of screen)
            xPercentage = (xdiff / (w.x / 2)) * 100
            // 4. Convert that to a percentage of the maximum rotation we allow for the neck
            dx = ((degreeLimit * xPercentage) / 100) * -1
        }

        // Right (Rotates neck right between 0 and degreeLimit)
        if (x >= w.x / 2) {
            xdiff = x - w.x / 2
            xPercentage = (xdiff / (w.x / 2)) * 100
            dx = (degreeLimit * xPercentage) / 100
        }
        // Up (Rotates neck up between 0 and -degreeLimit)
        if (y <= w.y / 2) {
            ydiff = w.y / 2 - y
            yPercentage = (ydiff / (w.y / 2)) * 100
            // Note that I cut degreeLimit in half when she looks up
            dy = ((degreeLimit * 0.5 * yPercentage) / 100) * -1
        }
        // Down (Rotates neck down between 0 and degreeLimit)
        if (y >= w.y / 2) {
            ydiff = y - w.y / 2
            yPercentage = (ydiff / (w.y / 2)) * 100
            dy = (degreeLimit * yPercentage) / 100
        }
        return { x: dx, y: dy }
    }



    function Animated3Dmodel() {



        const actions = useRef()
        const { size } = useThree()

        const group = useRef()
        const { nodes, animations, materials } = useGLTF(url)


        const [mixer] = useState(() => new THREE.AnimationMixer())

        useEffect(() => {
            actions.current = { idle: mixer.clipAction(animations[0], group.current) }
            actions.current.idle.play()
            return () => animations.forEach((clip) => mixer.uncacheClip(clip))
        }, [mixer, animations])

        useFrame((state, delta) => {
            mixer.update(delta)
            const mouse = { x: size.width / 2 + (state.mouse.x * size.width) / 2, y: size.height / 2 + (-state.mouse.y * size.height) / 2 }
            moveJoint(mouse, nodes.mixamorigNeck)
            moveJoint(mouse, nodes.mixamorigSpine)
        })


        function SetMaterial(material) {
            if (material.map) material.map.anisotropy = 16;
            return material
        }

        return (
            <group ref={group} {...props} dispose={null} scale={[200.0, 200.0, 200.0]} position={[0.0, -1.5, 0.0]} rotation={[0, 0, 0]}>
                <primitive object={nodes["mixamorigHips"]} />
                <skinnedMesh receiveShadow castShadow geometry={nodes["Girl_Set03_Top_geo"].children[0].geometry} skeleton={nodes["Girl_Set03_Top_geo"].children[0].skeleton} material={SetMaterial(materials.Girl_Set03)}> </skinnedMesh>
                <skinnedMesh receiveShadow castShadow geometry={nodes["Girl_Set03_Top_geo"].children[1].geometry} skeleton={nodes["Girl_Set03_Top_geo"].children[1].skeleton} material={materials.Girl_Body}></skinnedMesh>
                <skinnedMesh receiveShadow castShadow geometry={nodes["Girl_Set03_Top_geo"].children[2].geometry} skeleton={nodes["Girl_Set03_Top_geo"].children[2].skeleton} material={materials.Girl_Face}></skinnedMesh>
                <skinnedMesh receiveShadow castShadow geometry={nodes["Girl_Set03_Top_geo"].children[3].geometry} skeleton={nodes["Girl_Set03_Top_geo"].children[3].skeleton} material={materials.Girl_Set02_Hair}></skinnedMesh>
                <spotLight castShadow={true} position={[0, 1, 2]}></spotLight>
            </group>
        )
    }

	return (
        <Animated3Dmodel></Animated3Dmodel>
	)
}

export default GirlCharacter;

useGLTF.preload(url)
