import './App.css';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';

//Router path
import ScrollToTop from './ScrollToTop'
import HomePage from './Components/Pages/HomePage'
import ANewLeaf from './Components/Pages/Gaming/ANewLeaf'
import MitrilGames from './Components/Pages/Gaming/MitrilGames'
import KreativaBrian from './Components/Pages/WebDev/KreativaBrian'

const theme = createTheme({
    typography: {
        fontFamily: [
            'Mouse Memoirs',
            'sans-serif',
        ].join(','),
    },
    palette: {

        primary: { main: "rgba(26,26,29,1)"},
        secondary: { main: "rgba(149,7,64,1)" },
        tertiary: { main: "rgba(195,7,63,1)" },
        quaternary: { main: "rgba(226,209,195,1)" },
        quinary: { main: "rgba(253,252,251,1)" },

    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <ScrollToTop/>
                    <Routes>
                    <Route path="/" element={<HomePage />}></Route>
                    <Route path="/gaming/anewleaf" element={<ANewLeaf />}></Route>
                    <Route path="/gaming/mitrilGames" element={<MitrilGames />}></Route>
                    <Route path="/webdev/kreativaBrian" element={<KreativaBrian />}></Route>
                    </Routes>
            </Router>
      </ThemeProvider>
  
  );
}

export default App;
