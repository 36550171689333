import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProjectOnHover from '../Components/ProjectOnHover'
import TitleAndSub from '../Layout/TitleAndSub'
import { useTheme } from '@mui/material/styles';

function SixImages(props) {

	const mobile = useMediaQuery('(max-width:600px)');

	const tablet = useMediaQuery('(max-width:1800px)');

	const theme = useTheme();

	function Image(customProps) {

		return (
			<Grid item mr={mobile ? 2 : 3} ml={mobile ? 2 : 0} mb={mobile ? 5 : tablet ? 2 : 0}
				style={{
					backgroundColor: "black",
					height: "380px",
					width: mobile ? "380px" : customProps.width,
				}}
			>
				<ProjectOnHover
					backgroundImage={`url(${props.backgroundImages[customProps.index]})`}
					titlePlaceHolder={props.titlePlaceHolders[customProps.index]}
					title={props.titles[customProps.index]}
					subtitle={props.subTitles[customProps.index]}
					route={props.routes[customProps.index]}
				></ProjectOnHover>
			</Grid>
		)
    }

	return (
		<Grid
			container
			
			style={{

				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
			}}

		>
			<TitleAndSub
				title={<h1 style={{ color: theme.palette.secondary.main }}>Turn your idea into a startup</h1>}
				subtitle={
					<h2
						style={{ color: theme.palette.primary.main }}
					>
						We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play
					</h2>}
			>
				Turn your idea into a startup
			</TitleAndSub>
			<Grid

				container
				style={{
					backgroundRepeat: `no-repeat`,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Image
					width={"380px"}
					index={0}
				></Image>
				<Image
					width={"760px"}
					index={1}
				></Image>
				<Image
					width={"380px"}
					index={2}
				></Image>
			</Grid>

			<Grid
				container

				style={{

					backgroundRepeat: `no-repeat`,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",

				}}

			>
				<Image
					width={"380px"}
					index={3}
				></Image>
				<Image
					width={"760px"}
					index={4}
				></Image>
				<Image
					width={"380px"}
					index={5}
				></Image>
			</Grid>

		</Grid>

	);
}

export default SixImages;
