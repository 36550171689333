import React, {
	useState
} from "react";

import { useNavigate, } from "react-router-dom";
import { useSpring, animated } from 'react-spring'
import { useTheme } from '@mui/material/styles';


function ProjectOnHover({ children, ...props }) {

	let navigate = useNavigate();

	const [hovered, setHover] = useState(false)

	const theme = useTheme();

	const { boxShadow } = useSpring({

		boxShadow: (hovered ? `0px 1px 17px 0px ${theme.palette.primary.main}` : `0px 0px 0px 0px ${theme.palette.primary.main}`),

		config: {
			duration: 600
		},

	})

	const { borderRadius } = useSpring({

		borderRadius: (hovered ? "25px" : "0px"),

		config: {
			duration: 600
		},

	})

	const style = useSpring({

		to: {
			transform: `scale(${hovered ? 1.1 : 1.0})`,
	
		},
		config: {
			duration: 600
		},
	})

	function Redirect() {

		navigate(props.route);

	}

	return (
		<animated.div
			onPointerOver={() => setHover(true)}
			onPointerLeave={() => setHover(false)}
			onClick={Redirect}
			style={{
				borderWidth: 4,
				border: "solid",
				boxShadow: boxShadow,
				borderRadius: borderRadius,
				borderColor: theme.palette.quaternary.main,
				width: '100%',
				height: '100%',
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				cursor: "pointer",
				backgroundImage: `${props.backgroundImage}`,
				backgroundRepeat: `no-repeat`,
				backgroundSize: "cover",
				backgroundPosition: "center bottom",
				...style
			}}
		>



		</animated.div>

	);
}

export default ProjectOnHover;
