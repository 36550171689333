import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TitleAndSub from '../../Components/Layout/TitleAndSub'

import DescriptionAndLogo from '../../Components/Components/DescriptionAndLogo'

function HeaderProject({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');
	const theme = useTheme();

	function Header() {
		return (
			<Grid
				container

				style={{
					height: "600px",
					width: "100vw",
					backgroundImage: `url(${props.backgroundImage})`,
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover"
				}}
			>
				<Grid
					item
					xs={mobile ? 0 : 3}
				></Grid>

				<Grid
					item
					xs={mobile ? 12 : 6}

					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Box
						style={{
							height: mobile ? "256px" : "512px",
							width: mobile ? "256px" : "512px",
							backgroundImage: `url(${props.logo})`,
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover"
						}}

					></Box>
				</Grid>

				<Grid
					item
					xs={3}
				></Grid>


			</Grid>
		)
    }


	return (
		<Grid
			container

		>
			<Header></Header>

			<TitleAndSub
				title={<h1 style={{ color: theme.palette.secondary.main }} >{props.titles[0]}</h1>}
				subtitle={<h3 style={{ color: theme.palette.primary.main }} >{props.subTitles[0]}</h3>}
			></TitleAndSub>

			<DescriptionAndLogo
				companyLogo={props.companyLogo}
				companyName={props.titles[0]}
				subtitle={props.subTitles[1] }
			>
			</DescriptionAndLogo>

			
		</Grid>

	);
}

export default HeaderProject;
