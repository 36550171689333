
import Grid from '@mui/material/Grid';
import TitleAndSub from '../Layout/TitleAndSub'
import { useTheme } from '@mui/material/styles';


import './FourBoxLinear.css';
import ImageOnHover from '../Components/ImageOnHover'
import useMediaQuery from '@mui/material/useMediaQuery';


function FourBoxLinear({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');
	const theme = useTheme();
	//backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main} , 50% ,${theme.palette.tertiary.main})`,
	function Image(customProps) {

		return (
			<Grid
				item
				m={mobile?4:2}
			
				style={{
					width: mobile ? '100vw' : '380px',
					height: '50vh',
				}}
			>
				<ImageOnHover
					backgroundImageReplace={`url(${props.backgroundImagesReplace[customProps.index]})`}
					backgroundImage={`url(${props.backgroundImages[customProps.index]})`}
					titlePlaceHolder={props.titlePlaceHolders[customProps.index]}
					title={props.titles[customProps.index]}
					subtitle={props.subTitles[customProps.index]}
				></ImageOnHover>
			</Grid>
		)
    }

	return (
		<Grid
			container
			pt={4}
		
			style={{
				padding: 0,		
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",

			}}
		>
			<TitleAndSub
				title={<h1 style={{ color: theme.palette.secondary.main }}>Turn your idea into a startup</h1>}
				subtitle={
					<h2
						style={{ color: theme.palette.primary.main }}
					>
						We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play
					</h2>}
			>
				Turn your idea into a startup
			</TitleAndSub>

			<Image
				index={0}

			></Image>

			<Image
				index={1}
			></Image>

			<Image
				index={2}
			></Image>

			<Image
				index={3}
			></Image>



		</Grid>

	);
}

export default FourBoxLinear;