
import './Header.css';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Image from '../../video/backVideo.mp4';
import ImageMobile from '../../video/backVideoMobile.mp4';
import { useTheme } from '@mui/material/styles';

function Header() {

	const theme = useTheme();
	const mobile = useMediaQuery('(max-width:1000px)');


	return (
		<Grid
			container
			spacing={mobile ? 0 : 2}
	
			
		>
			<div
				style={{
					
					zIndex: "-1",
					position: "absolute",
					width: "100%",
					top:0,
					left: 0,
					
				}}

			>
				<video className="header_video"  autoPlay loop muted>
					<source src={mobile ? ImageMobile : Image} type='video/mp4' />
				</video>


			</div>
	
			<Grid item xs={mobile ? 1 : 3}></Grid>
			<Grid
				className="background-header"
				item
				xs={mobile ? 10 : 6}
				style={{
			
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",

				}}

			>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Box
						m={1}
						textAlign="center"
		
					>
						<h1
							style={{
								color: theme.palette.secondary.main,
								fontSize: mobile ? "48px" : "72px"
                            }}
						>
							The Ramble Studio
						</h1>

						<h2
							style={{
								color: theme.palette.primary.main,
								fontSize: mobile ? "22px" : "28px"
							}}

						>
							The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color.
						</h2>
					</Box>
					

					<Box
				
					>
						<Button
							style={{
								color: theme.palette.tertiary.main,
								borderRadius: 35,
								margin:"18px",
								padding: "18px 36px",
								fontSize: "22px",
								border: `3px solid ${theme.palette.tertiary.main}`,

							}}
							variant="outlined"
						>Discover More</Button>
					</Box>
				</Box>

			</Grid>


			<Grid item xs={mobile ? 1 : 3}></Grid>
		</Grid>
	);
}

export default Header;