
//Layouts
import Header from '../../Components/Layout/Header';
import NavBar from '../Layout/NavBar.js'
import CustomFooter from '../Layout/CustomFooter.js'

import FourBoxLinear from '../Layout/FourBoxLinear'
import SixImages from '../Layout/SixImages'
import Team from '../Layout/Team'
import HomePage3DCharacter from '../Layout/HomePage3DCharacter'
import EmailForm from '../Layout/EmailForm'

import Image0 from '../../img/Desktop/WhoWheAre0.png'; //
import Image1 from '../../img/Team2.png'; //

//Images
import Image_2DArt from '../../img/Home/2DArt.jpg'; //
import Image_2DArt_Blur from '../../img/Home/2DArtBlur.jpg'; //
import Image_3DArt from '../../img/Home/3DArtCharacter.jpg'; //
import Image_3DArt_Blur from '../../img/Home/3DArtCharacterBlur.jpg'; //
import Image_ShaderTechArt from '../../img/Home/ShaderTechArt.jpg'; //
import Image_ShaderTechArt_Blur from '../../img/Home/ShaderTechArtBlur.jpg'; //
import Image_Coding from '../../img/Home/Coding.jpg'; //
import Image_CodingBlur from '../../img/Home/CodingBlur.jpg'; //

//Images projects
import Image_ANewLeaf from '../../img/Home/ANewLeafMemories.jpg'; //


function HomePage() {

	return (
		<div
			style={{
				background:"linear-gradient(270deg, rgba(253,252,251,0) 0%, rgba(249,246,243,0.45702030812324934) 50%, rgba(226,209,195,1) 100%)"
            }}

		>
			<NavBar></NavBar>
			<Header></Header>
			<HomePage3DCharacter></HomePage3DCharacter>
		

			<FourBoxLinear
				backgroundImages={[
					Image_2DArt,
					Image_3DArt,
					Image_ShaderTechArt,
					Image_Coding
				]}

				backgroundImagesReplace={[
					Image_2DArt_Blur,
					Image_3DArt_Blur,
					Image_ShaderTechArt_Blur,
					Image_CodingBlur
				]}

				titlePlaceHolders={[
					<h1>2D Art</h1>,
					<h1>3D Art</h1>,
					<h1>Shader Tech Art</h1>,
					<h1>Coding</h1>
				]}

				titles={[
					<h1>2D Art</h1>,
					<h1>3D Art</h1>,
					<h1>Shader Tech Art</h1>,
					<h1>Coding</h1>
				]}

				subTitles={[
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
				]}
			></FourBoxLinear>


			<SixImages
				backgroundImages={[
					Image_ANewLeaf,
					Image0,
					Image0,
					Image0,
					Image0,
					Image0
				]}

				titlePlaceHolders={[
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
				]}

				titles={[
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>,
					<h1>I Will Fade Out</h1>
				]}

				subTitles={[
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
					<h2>School of Dragons is a PC MMO Adventure game set on the Theme</h2>,
				]}

				routes={[
					"/gaming/anewleaf",
					"/gaming/mitrilGames",
					"/webdev/kreativaBrian"

				]}

			></SixImages>



			<Team
				backgroundImages={[
					Image0,
					Image0,
					Image0,
					Image0,
				]}

				backgroundImagesReplaced={[
					Image1,
					Image1,
					Image1,
					Image1,
				]}

			></Team>



			<EmailForm></EmailForm>
			<CustomFooter></CustomFooter>

		</div>
	);
}

export default HomePage;
