import React, {
	useState
} from "react";

import Grid from '@mui/material/Grid';
import { useSpring, animated } from 'react-spring'
import { useTheme } from '@mui/material/styles';

function ImageOnHover({ children, ...props }) {

	const [hovered, setHover] = useState(false)
	const theme = useTheme();

	const primary = theme.palette.secondary.main.replace(/[\d]+\)$/g, '0.2)')
	const secondary = theme.palette.tertiary.main.replace(/[\d]+\)$/g, '0.7)')


	const { opacity } = useSpring({

		opacity: (hovered ? 1 : 0),

		config: {
			duration: 600
		},

	})

	const { opacity2 } = useSpring({

		opacity2: (hovered ? 0 : 1),

		config: {
			duration: 600
		},

	})

	const { border } = useSpring({

		border: (hovered ? 10 : 4),

		config: {
			duration: 200
		},

	})

	const styles = useSpring({
		from: { y: (hovered ? 200 : 0) },
		to: { y: (hovered ? 0 : 200) },
		config: {
			duration: 600
		},
	})


	const styles2 = useSpring({

		from: { y: (hovered ? 200 : -300) },
		to: { y: (hovered ? -300 : 200) },
		config: {
			duration: 600
		},
	})

	//backgroundImage: ,
	return (
		<animated.div

			style={{
				borderWidth: border,
				borderStyle: "solid",
				borderColor: theme.palette.tertiary.main,
				borderRadius: "25px",
				width: '100%',
				height: '100%',
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
		
				backgroundImage: `${hovered ? `linear-gradient(to bottom, ${primary}, ${secondary}), ${props.backgroundImageReplace}` : props.backgroundImage }`,
	
				backgroundRepeat: `no-repeat`,
				backgroundSize: "cover",
				backgroundPosition: "center bottom"
			}}
		>

			<animated.div
				onPointerOver={() => setHover(true)}
				onPointerLeave={() => setHover(false)}
				style={{
					borderRadius: "25px",
					width: '100%',
					height: '100%',
					opacity: opacity,
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",

				
				
				}}
			>
				<Grid
					container
					spacing={1}
					style={{ padding: 20 }}
				>
					<Grid item xs={1}></Grid>
					<Grid item xs={10}>

						<animated.div
							style={{
								color: theme.palette.secondary.main,
								...styles,
							}}
						>
							{props.title}
							
						</animated.div>
						<animated.div
							style={{
								color: theme.palette.quinary.main,
								textShadow:'-1px 1px 0 #000',
								...styles,
							}}
						>
							{props.subtitle}
						</animated.div>
					</Grid>
					<Grid item xs={1}></Grid>

				</Grid>
			</animated.div>

			<animated.div
				style={{

					color: theme.palette.secondary.main,
					position: "absolute",
					opacity: opacity2,
					pointerEvents: "none",
					...styles2
				}}
			>
				{props.titlePlaceHolder}
			</animated.div>

			<animated.div
				style={{
					borderRadius: "25px",
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.quinary.main,
					paddingRight: "40px",
					paddingLeft:"20px",
					position: "absolute",
					opacity: opacity2,
					WebkitClipPath: `polygon(0% 20%, 92% 14%, 88% 88%, 0% 100%)`,
					clipPath: `polygon(0% 20%, 92% 14%, 88% 88%, 0% 100%)`,
					pointerEvents: "none",
					...styles2
				}}
			>
				{props.titlePlaceHolder}
			</animated.div>



		</animated.div>

	);
}

export default ImageOnHover;