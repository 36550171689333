import * as THREE from "three"
import { Suspense } from 'react'
import { OrthographicCamera } from "@react-three/drei";
import React from "react"
import { Canvas } from '@react-three/fiber'



function Character3D({ children, ...props }) {



    function UpdateGL(state) {
        state.gl.setClearColor(0x000000, 0);
        state.gl.toneMapping = THREE.ACESFilmicToneMapping;
        state.gl.toneMappingExposure = 1.25;
        state.gl.shadowMap.enabled = true;
        state.gl.outputEncoding = THREE.sRGBEncoding;
    }

    return (
        <Suspense fallback={<></>}>
            <Canvas
                style={{ height: "500px" }}
                onCreated={(state) => UpdateGL(state)}
            >
   
            <OrthographicCamera makeDefault position={[0, 0, 10]} rotation={[0, 0, 0]} zoom={120} />
            <hemisphereLight color={"#080820"} groundColor={"#ffeeb1"} position={[1, 1, -2]} />
                {children}
            </Canvas>
        </Suspense>

	);
}

export default Character3D;




