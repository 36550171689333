import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import TitleAndSub from '../../Components/Layout/TitleAndSub'
import { useTheme } from '@mui/material/styles';

function ProjectThreeVideoLinear({ children, ...props }) {

	const theme = useTheme();
	const mobile = useMediaQuery('(max-width:600px)');

	function Video(customProps) {
		return (
			<Grid
				item
				m={2}
			>
				<video style={{ display: "flex" }} width="480" height="auto" autoPlay loop muted>
					<source src={props.video[customProps.index]} type='video/mp4' />
				</video>
			</Grid>
		)
	}

	return (
		<Grid

			container

		>
			<TitleAndSub
				title={<h1 style={{ color: theme.palette.secondary.main }} >{props.title}</h1>}
				subtitle={<h2 style={{ color: theme.palette.primary.main }} >{props.subTitle}</h2>}
			></TitleAndSub>

			<Grid
				container

				style={{
					padding: 0,
					backgroundRepeat: `no-repeat`,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",

				}}
			>
				{
					props.video.map((item, index) =>
						<Video
							key={index}
							index={index}
						></Video>
					)
				}
				


			</Grid>

		</Grid>

	);
}

export default ProjectThreeVideoLinear;