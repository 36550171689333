import { useGLTF } from '@react-three/drei'

import React, { useRef } from "react"
import { useFrame } from '@react-three/fiber'


var url = './3DModels/CubePet_Sito2.glb';


function MitrilGamesCharacter({ children, ...props }) {

    const { nodes, materials } = useGLTF(url)


    function Model(props) {
        // This reference gives us direct access to the THREE.Mesh object
        const ref = useRef()


        useFrame(() => (ref.current.rotation.y += 0.01))

        // Return the view, these are regular Threejs elements expressed in JSX
        return (
            <group ref={ref} {...props} dispose={null} scale={[200.0, 200, 200]} position={[0.0, -1.5, 0.0]} rotation={[0.3, 0.3, 0]}>
                <primitive object={nodes["CubePet-Dino"]} />
                <mesh receiveShadow castShadow geometry={nodes["CubePet-Dino"].geometry} material={materials["CubePet-Dino"]}></mesh>
                <spotLight castShadow={true} position={[0, 1, 0]}></spotLight>
            </group>
        )
    }


    return (
        <Model position={[0, 0, 0]} />
    )
}

export default MitrilGamesCharacter;

useGLTF.preload(url)
