import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import TitleAndSub from '../../Components/Layout/TitleAndSub'
import { useTheme } from '@mui/material/styles';

function ProjectFourImages({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');
	const theme = useTheme();

	function Image(customProps) {
		return (

			<Grid
				item
				style={{
					width: mobile ? '100vw' : '768px',
					height: mobile ? '56vw' : '432px',
					backgroundImage: `url(${props.Images[customProps.index]})`,
					backgroundRepeat: `no-repeat`,
					backgroundSize: "cover",
				}}
			>

			</Grid>
		)
    }

	return (
		<Grid
			container
		>
			<TitleAndSub
				title={<h1 style={{ color: theme.palette.secondary.main }} >{props.title}</h1>}
				subtitle={<h2 style={{ color: theme.palette.primary.main }} >{props.subTitle}</h2>}
			></TitleAndSub>

			<Grid
				container
		
				style={{
					padding: 0,
					backgroundRepeat: `no-repeat`,
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",

				}}
			>
				<Image
					index={0}
				></Image>

				<Image
					index={1}
				></Image>

				<Image
					index={2}
				></Image>
				<Image
					index={3}
				></Image>

			</Grid>

		</Grid>

	);
}

export default ProjectFourImages;