import React, {
	useState
} from "react";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useSpring, animated } from 'react-spring'



import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';


function CheckListModule(props) {

	const [hovered, setHover] = useState(false)
	const mobile = useMediaQuery('(max-width:600px)');

	const theme = useTheme();


	const style = useSpring({

		to: {
			transform: `scale(${hovered ? 1.1 : 1.0})`,

		},
		config: {
			duration: 600
		},
	})

	return (
		<Box
			m={mobile ? 0 : 4}
			mt={mobile ? 4 : 0}

		
		>
			<animated.div
				onPointerOver={() => setHover(true)}
				onPointerLeave={() => setHover(false)}

				style={{
					...style
				}}

			>
				<Paper
					elevation={3}

					style={{
						width: mobile ? '90vw':'500px',
						borderRadius: "25px",

						backgroundColor: theme.palette.secondary.main

					}}

				>
					<Grid
						container
				
						style={{ padding: 20 }}
					>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									color: theme.palette.quinary.main,
									textShadow: '-2px 2px 0 #000',
								}}

							>
								{props.icon}
								<h1 style={{ margin: 0, marginLeft:5 }}>   {props.title}</h1>
							</div>


						</Grid>

						<Grid item xs={1}></Grid>

					</Grid>
					<Grid
						container
						spacing={1}
						style={{ padding: 20 }}
					>
						<Grid item xs={1}></Grid>
						<Grid item xs={5}>

							<List
								style={{
									color: theme.palette.quinary.main,
									textShadow: '-2px 2px 0 #000',
					
								}}

							>
								{props.leftItems.map((item, index) =>
									<div key={index} >{item}</div>
								)}
							</List>

						</Grid>
						<Grid item xs={5}>

							<List
								style={{
									color: theme.palette.quinary.main,
									textShadow: '-2px 2px 0 #000',
								}}
							>
								{props.rightItems.map((item, index) =>
									<div key={index}>{item}</div>
								)}
							</List>

						</Grid>
						<Grid item xs={1}></Grid>

					</Grid>



				</Paper>

			</animated.div>
		</Box>

	);
}

export default CheckListModule;
