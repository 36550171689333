import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

function TitleAndSub({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');

	return (
		<>
			<Grid
				container
				spacing={1}
				style={{ padding: 20 }}
	
			>
				<Grid item xs={mobile ? 1 : 4}></Grid>
				<Grid item xs={mobile ? 10 : 4}
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box
						fontSize={mobile ? "16px" : "20px"}
						m={0}
						textAlign="center"
					>
						{props.title}
						{props.subtitle}
					</Box>

				</Grid>
				<Grid item xs={mobile ? 1 : 4}></Grid>
			</Grid>
		</>

	);
}

export default TitleAndSub;