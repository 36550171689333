import React, {useState} from 'react'
import { useForm, ValidationError } from "@formspree/react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import TitleAndSub from '../Layout/TitleAndSub'

function EmailForm() {
    const mobile = useMediaQuery('(max-width:1000px)');

    const theme = useTheme();

    const [captcha, setCaptcha] = useState(false);
    const [state, handleSubmit] = useForm("xrgjqben");


    if (state.succeeded) {
        return (

            <Grid
                container
                style={{}}
                mt={10}
                mb={10}

            >
                <Grid item xs={3}></Grid>
                <Grid item xs={6}

                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    < h1 > Thanks for your submission!</h1 >;


                </Grid>
                <Grid item xs={3}></Grid>
               

            </Grid>
            
        )
    }

    function onChange(value) {
        if (!captcha)
            setCaptcha(true);
    }

    function FormLayoutDesktop() {
        return (
            <>
                <h1 htmlFor="name" style={{ color: theme.palette.primary.main}}>Full Name</h1>
                    <input id="name" type="text" name="name" required style={{ minWidth: "50vw", fontSize: "32px", color: theme.palette.primary.main}}/>
                    <ValidationError prefix="Name" field="name" errors={state.errors}  />

                    <h1 htmlFor="email" style={{ color: theme.palette.primary.main }} >Email Address</h1>
                    <input id="email" type="email" name="email" required style={{ minWidth: "50vw", fontSize: "32px", color: theme.palette.primary.main }}/>
                    <ValidationError prefix="Email" field="email" errors={state.errors} />

                    <h1 htmlFor="message" style={{ color: theme.palette.primary.main }} >Message</h1>
                    <textarea id="message" name="message" required  style={{ minWidth: "50vw", fontSize: "32px", color: theme.palette.primary.main }} ></textarea>
                    <ValidationError prefix="Message" field="message" errors={state.errors}  />
                <ValidationError errors={state.errors} className="formErrors" />
            </>
        )
    }

    function FormLayoutMobile() {
        return (
            <>
                <h1 htmlFor="name" style={{ color: theme.palette.primary.main }}>Full Name</h1>
                <input id="name" type="text" name="name" required style={{ width: "80vw", fontSize: "32px", color: theme.palette.primary.main }} />
                <ValidationError prefix="Name" field="name" errors={state.errors} />

                <h1 htmlFor="email" style={{ color: theme.palette.primary.main }} >Email Address</h1>
                <input id="email" type="email" name="email" required style={{ width: "80vw", fontSize: "32px", color: theme.palette.primary.main }} />
                <ValidationError prefix="Email" field="email" errors={state.errors} />

                <h1 htmlFor="message" style={{ color: theme.palette.primary.main }} >Message</h1>
                <textarea id="message" name="message" required  style={{ width: "80vw", fontSize: "32px", color: theme.palette.primary.main }} ></textarea>
                <ValidationError prefix="Message" field="message" errors={state.errors} />
                <ValidationError errors={state.errors} className="formErrors" />
            </>
        )
    }

    return (
        <Grid
            container
            pt={10}
            pb={10}


        >
            <TitleAndSub
                title={<h1 style={{ color: theme.palette.secondary.main }} >Turn your idea into a startup</h1>}
                subtitle={<h2 style={{ color: theme.palette.primary.main }} >We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play</h2>}
            >
                Turn your idea into a startup
            </TitleAndSub>

            <Grid item xs={3}></Grid>
            <Grid item xs={6}

                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <form method="POST" onSubmit={handleSubmit}  >
                    {mobile ?
                        <FormLayoutMobile></FormLayoutMobile>
                        :
                        <FormLayoutDesktop></FormLayoutDesktop>
                    }
                   

                    {!captcha ?
                        <ReCAPTCHA
                            sitekey="6LcQYHceAAAAALLhSnw_zG7wTudUeyY1DIx4XTB6"
                            onChange={onChange}
                        /> :
                        <Button className="form_button" type="submit" disabled={state.submitting}
                            style={{
                                color: theme.palette.tertiary.main,
                                borderRadius: 35,
                                marginTop: "10px",
                                padding: "10px 36px",
                                fontSize: "22px",
                                border: `2px solid ${theme.palette.tertiary.main}`,
                         
                            }}
                        >
                            Submit
                        </Button>
                    }

                </form>


            </Grid>
            <Grid item xs={3}></Grid>

            
        </Grid>
    );
}

export default EmailForm;
