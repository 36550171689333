import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

function DescriptionAndLogo({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');
	const theme = useTheme();

	return (
		<Grid
			container

		>
			<Grid item xs={1}></Grid>
			<Grid item xs={mobile ? 10 : 6}

			>
				<Box
					fontSize={mobile ? "16px" : "22px"}
					m={0}
					textAlign="left"
				>
					<h3
						style={{
							margin: "0!important",
							color: theme.palette.secondary.main
						}}
					>Project Description</h3>

				</Box>

				<Box
					fontSize={mobile ? "16px" : "22px"}
					m={0}
					textAlign="left"
				>
					<h4
						style={{
							margin: "0!important",
							color: theme.palette.primary.main
						}}
					>{props.subtitle}</h4>


				</Box>


			</Grid>
			<Grid item xs={1}></Grid>

			<Grid item xs={mobile ? 12 : 3}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column"
				}}
			>
				<Box
					fontSize={mobile ? "16px" : "22px"}
					m={0}
					textAlign="center"
				>
					<h3
						style={{
							color: theme.palette.secondary.main
						}}

					>{props.companyName}</h3>
				</Box>

				<Box
					style={{
						height: "256px",
						width: "256px",
						backgroundImage: `url(${props.companyLogo})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover"
					}}
				>
				</Box>
			</Grid>
		</Grid>

	);
}

export default DescriptionAndLogo;