import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

//social icons
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';

function CustomFooter() {

    const theme = useTheme();

    const mobile = useMediaQuery('(max-width:1000px)');

    function Logo() {
        return (
            <Button style={{ color: theme.palette.quaternary.main, fontSize: "22px" }}>LOGO</Button>
        )
    }

    function MenuButton({ children, ...props }) {
        return (
            <Typography m={mobile ? 0 : 1} variant="h6" component="div" sx={{ flexGrow: props.flexGrow }}>
                <Button style={{ color: theme.palette.quaternary.main, fontSize: mobile ? "18px" : "22px" }}>{children}</Button>
            </Typography>
        )
    }

    function SocialIcon({ children, ...props }) {
        return (
            <Typography m={mobile ? 0 : 1} variant="h6" component="div" sx={{ flexGrow: props.flexGrow }}>
                {children}
            </Typography>
        )
    }

    function MenuButtons() {
        return (
            <>
          
                <MenuButton flexGrow={0}>Home</MenuButton>
                <MenuButton flexGrow={0}>Projects</MenuButton>
                <MenuButton flexGrow={0}>Team</MenuButton>
                <MenuButton flexGrow={0}>Contact Us</MenuButton>
                {mobile ? <></> : <MenuButton flexGrow={0.5}></MenuButton>}
            </>
        )
    }

    function SocialButtons() {
        return (
            <>
                <SocialIcon flexGrow={0}><FacebookIcon color="quaternary" fontSize="large" ></FacebookIcon> </SocialIcon>
                <SocialIcon flexGrow={0} ><LinkedInIcon color="quaternary" fontSize="large" ></LinkedInIcon></SocialIcon>
                <SocialIcon flexGrow={0}><LanguageIcon ml={2} color="quaternary" fontSize="large" ></LanguageIcon></SocialIcon>
            </>
        )

    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Logo></Logo>
                </Toolbar>

                {mobile ?
                    <>
                        <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <MenuButtons></MenuButtons>
                        </Toolbar>
                        <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <SocialButtons></SocialButtons>
                        </Toolbar>
                    </>          
                    :   
                    <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <MenuButtons></MenuButtons>
                        <SocialButtons></SocialButtons>
                    </Toolbar>
                }
               
            </AppBar>
        </Box>

    );
}

export default CustomFooter;