import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSpring, animated } from 'react-spring'

function NavBar() {

    const theme = useTheme();

    const mobile = useMediaQuery('(max-width:1000px)');

    const [open, setOpen] = useState(false);

    function onChange(value) {

        setOpen(!open)        
    }

    const { height } = useSpring({

        height: (open ? "50px" : "0px"),

        config: {
            duration: 600
        },

    })

    const styles = useSpring({

        from: { y: (open ? -50 : 0) },
        to: { y: (open ? 0 : -100) },
        config: {
            duration: 600
        },
    })

    function Logo() {
        return (
            <Button style={{ color: theme.palette.quinary.main, fontSize: "22px" }} onClick={ mobile ? onChange : null}>LOGO</Button>
        )
    }

    function MenuButton({ children, ...props }) {
        return (
            <Typography m={mobile ? 0 : 1} variant="h6" component="div" sx={{ flexGrow: props.flexGrow }}>
                <Button style={{ color: theme.palette.quinary.main, fontSize: mobile ? "18px" : "22px" }}>{children}</Button>
            </Typography>
        )
    }

    function MenuButtons() {
        return (
            <>
                {mobile ? <></> : <MenuButton flexGrow={1}></MenuButton>}
                <MenuButton flexGrow={0}>Home</MenuButton>
                <MenuButton flexGrow={0}>Projects</MenuButton>
                <MenuButton flexGrow={0}>Team</MenuButton>
                <MenuButton flexGrow={0}>Contact Us</MenuButton>
            </>
        )
    }



	return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                {mobile ?
                    <>
                        <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                             <Logo></Logo>
                        </Toolbar>
                        <animated.div style={{ ...styles }}>
                            <animated.div style={{ height: height}}>
                                <Toolbar style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <MenuButtons></MenuButtons> 
                                </Toolbar>
                            </animated.div>
                        </animated.div>
                    </>
                    :
                    <Toolbar>
                        <Logo></Logo>
                        <MenuButtons></MenuButtons>
                    </Toolbar>

                }
            </AppBar>
        </Box>

	);
}

export default NavBar;
