
import Grid from '@mui/material/Grid';
import TitleAndSub from '../Layout/TitleAndSub'

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import useMediaQuery from '@mui/material/useMediaQuery';

import SwapImageOnHover from '../Components/SwapImageOnHover'
import { useTheme } from '@mui/material/styles';



function Text() {

	const mobile = useMediaQuery('(max-width:600px)');
	const theme = useTheme();

	return (
		<>
			<Grid item xs={mobile ? 2 : 1 }></Grid>

			<Grid item xs={mobile ? 6 : 5 }

			>
				<h1
					style={{ margin: "0", color: theme.palette.secondary.main }}

				>Alec Thompson</h1>

			</Grid>
			<Grid item xs={mobile ? 2 : 6 }></Grid>

			<Grid item xs={mobile ? 0 : 1 }></Grid>

			<Grid item xs={mobile ? 6 : 5 }
	
			>
				<h2
					style={{ margin: "0", color: theme.palette.secondary.main }}

				>Ceo/CoFounder</h2>

			</Grid>

			<Grid item xs={mobile ? 8: 6}></Grid>

			<Grid item xs={mobile ? 0 : 1}></Grid>

			<Grid item xs={mobile ? 8 : 6}

			>
				<h3 style={{ color: theme.palette.primary.main }} >And I love you like Kenye loves kanye. We need to restart the guman foundation</h3>

			</Grid>


		</>
	)
}

function ConditionalText() {

	const mobile = useMediaQuery('(max-width:600px)');

	if (mobile) {
		return (
			<Grid container
				style={{
		
					display: "flex",
					alignItems: "center",
					justifyContent: "center"

				}}
			>
				<Text></Text>
			</Grid>
		)
	}
	else
		return (
			<Grid container

			>
				<Text></Text>
			</Grid>
			
		)
}

function Card({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');


	return (
		<div
			style={{
				minWidth: mobile ? "100%":"400px",
				maxWidth: mobile ? "100%" : "400px",
			}}
		>
			<Grid
				container
			>
				<Grid container
					style={{
						height: "50px",
					}}
				>

				</Grid>

				<Grid container
				>
					<Grid item xs={mobile ? 3 : 1}></Grid>

					<Grid item xs={mobile ? 6 : 5}
						style={{
							height: "200px",

						}}
					>
						<SwapImageOnHover
							backgroundImage={props.backgroundImage}
							backgroundImageReplaced={props.backgroundImageReplaced}
						></SwapImageOnHover>
					</Grid>


				</Grid>

				<ConditionalText></ConditionalText>

				<Grid container

				>
					<Grid item xs={mobile ? 4 : 1}></Grid>

					<Grid item xs={1}
						style={{
							height: "35px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"

						}}
					>
						<FacebookIcon color="tertiary" fontSize="large" ></FacebookIcon>
					</Grid>
					<Grid item xs={1} ml={2}
						style={{
							height: "35px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"

						}}
					>
						<LinkedInIcon color="tertiary" fontSize="large" ></LinkedInIcon>
					</Grid>
					<Grid item xs={1} ml={2}
						style={{
							height: "35px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}
					>
						<LanguageIcon color="tertiary" fontSize="large" ></LanguageIcon>
					</Grid>


				</Grid>




			</Grid>


		</div>
	)
}

function Team({ children, ...props }) {
	const theme = useTheme();

	return (
		<>
			<Grid
				container
				spacing={1}
				style={{
					padding: 20,
					display: "flex",
					alignItems: "center",
					justifyContent:"center"
				}}

			>
				<TitleAndSub
					title={<h1 style={{ color: theme.palette.secondary.main }} >Turn your idea into a startup</h1>}
					subtitle={<h2 style={{ color: theme.palette.primary.main }} >We're constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play</h2>}
				>
					Turn your idea into a startup

				</TitleAndSub>

				<Card
					backgroundImage={props.backgroundImages[0]}
					backgroundImageReplaced={props.backgroundImagesReplaced[0]}

				></Card>
				<Card
					backgroundImage={props.backgroundImages[0]}
					backgroundImageReplaced={props.backgroundImagesReplaced[0]}

				></Card>
				<Card
					backgroundImage={props.backgroundImages[0]}
					backgroundImageReplaced={props.backgroundImagesReplaced[0]}
				></Card>
				<Card
					backgroundImage={props.backgroundImages[0]}
					backgroundImageReplaced={props.backgroundImagesReplaced[0]}

				></Card>

				
			</Grid>
		</>

	);
}

export default Team;
