import useMediaQuery from '@mui/material/useMediaQuery';

import ListItemText from '@mui/material/ListItemText';
import CheckListModule from '../../../Components/Components/CheckListModule'
import DescriptionIcon from '@mui/icons-material/Description';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PreviewIcon from '@mui/icons-material/Preview';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';

import NavBar from '../../Layout/NavBar.js'
import HeaderProject from '../../Layout/HeaderProject'
import ProjectThreeImagesLinear from '../../Layout/ProjectThreeImagesLinear'
import ProjectFourImages from '../../Layout/ProjectFourImages'
import ProjectThreeVideoLinear from '../../Layout/ProjectThreeVideoLinear'
import ProjectCheckList from '../../Layout/ProjectCheckList'
import CustomFooter from '../../Layout/CustomFooter.js'

//images
import Image_Header from '../../../img/Projects/Gaming/ANewLeaf/ANL_Illustrations01.jpg';
import Logo from '../../../img/Projects/Gaming/ANewLeaf/ANewLeafLogo.png';
import Company_Logo from '../../../img/Projects/Gaming/ANewLeaf/IronHeartStudiosLogo.png';

//Env Images
import Image_Env0 from '../../../img/Projects/Gaming/ANewLeaf/ANL_Illustrations01.jpg';
import Image_Env1 from '../../../img/Projects/Gaming/ANewLeaf/ANL_Illustrations02.jpg';
import Image_Env2 from '../../../img/Projects/Gaming/ANewLeaf/ANL_Illustrations03.jpg';

//2D Characters Images
import Image_Char2D_0 from '../../../img/Projects/Gaming/ANewLeaf/ANL_kickstarter01.jpg';
import Image_Char2D_1 from '../../../img/Projects/Gaming/ANewLeaf/ANL_kickstarter02.jpg';
import Image_Char2D_2 from '../../../img/Projects/Gaming/ANewLeaf/ANL_Character1.jpg';
import Image_Char2D_3 from '../../../img/Projects/Gaming/ANewLeaf/ANL_Character2.jpg';

//video
import Video from '../../../video/Projects/Gaming/ANewLeaf/CharacterCustomization.webm';
import Video_1 from '../../../video/Projects/Gaming/ANewLeaf/FarmCustomization.webm';
import Video_2 from '../../../video/Projects/Gaming/ANewLeaf/Inventory.webm';


//3D env
import Image_Env3D_0 from '../../../img/Projects/Gaming/ANewLeaf/3DEnv0.jpg';
import Image_Env3D_1 from '../../../img/Projects/Gaming/ANewLeaf/3DEnv1.jpg';
import Image_Env3D_2 from '../../../img/Projects/Gaming/ANewLeaf/3DEnv2.jpg';
import Image_Env3D_3 from '../../../img/Projects/Gaming/ANewLeaf/3DEnv3.jpg';

function KreativaBrian({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');

	const checkListFontSize = mobile ? '18px' : '22px'

	return (
		<div
			style={{
				background: "linear-gradient(270deg, rgba(253,252,251,0) 0%, rgba(249,246,243,0.45702030812324934) 50%, rgba(226,209,195,1) 100%)"
			}}
		>
			<NavBar></NavBar>
			<HeaderProject
				backgroundImage={Image_Header}
				logo={Logo}
				companyLogo={Company_Logo}
				titles={[
					"A New Leaf: Memories"
				]}

				subTitles={[
					"You wake up on the beach of a foreign island, unable to recall anything. Alone and equipped with only basic farming tools provided by the town residents, you aim to recover your memories. Complete quests and explore Omoide Island to unfold your story.",
					"A life simulation - farming game inspired by classics like Harvest Moon, Stardew Valley, Story of Seasons, and Animal Crossing. We take care about the full game production from scratch. The services we have offered for the customer were: Coding (gameplay and tools), Concept art , Illustrations , UI , 3D Character/Environment , Tech art and Sound."
				]}
			></HeaderProject>

			<ProjectCheckList
				checkListModules={[
					<CheckListModule
						title={"Coding"}
						icon={<DescriptionIcon style={{ fontSize: "35px" }}></DescriptionIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Game mechanics" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Character customization" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Gameplay editor" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Gameplay feedbacks" />,
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Save and load system" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Vegetation system" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Gameplay actions" />
						]}
					></CheckListModule>,

					<CheckListModule
						title={"3D Art"}
						icon={<ThreeDRotationIcon style={{ fontSize: "35px" }}></ThreeDRotationIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Isnbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
						]}
					></CheckListModule>,
					<CheckListModule
						title={"2D Art"}
						icon={<PreviewIcon style={{ fontSize: "35px" }}></PreviewIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Isnbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
						]}
					></CheckListModule>,
					<CheckListModule
						title={"Tech Art"}
						icon={<FilterBAndWIcon style={{ fontSize: "35px" }}></FilterBAndWIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Water shader" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Grass shader" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Vegetation shader" />
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Facial Expressions" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
						]}
					></CheckListModule>


				]}

			></ProjectCheckList>


			<ProjectThreeImagesLinear
				title={"Illustration"}
				subTitle={"Some of our Illustrations done for the game"}
				Images={[
					Image_Env0,
					Image_Env1,
					Image_Env2
				]}
			></ProjectThreeImagesLinear>

			<ProjectFourImages
				title={"Concept Art"}
				subTitle={"Concept art done for both kickstarter presentations and in-game future development"}
				Images={[
					Image_Char2D_0,
					Image_Char2D_1,
					Image_Char2D_2,
					Image_Char2D_3
				]}
			></ProjectFourImages>

			<ProjectFourImages
				title={"3D Characters"}
				subTitle={"Characters made for the game  fully customizables."}
				Images={[
					Image_Char2D_0,
					Image_Char2D_1,
					Image_Char2D_2,
					Image_Char2D_3
				]}
			></ProjectFourImages>

			<ProjectFourImages
				title={"3D Environment & Prop"}
				subTitle={"Assets , buildings and vegetation done for the game."}
				Images={[
					Image_Env3D_3,
					Image_Env3D_0,
					Image_Env3D_1,
					Image_Env3D_2,

				]}
			></ProjectFourImages>

			<ProjectThreeVideoLinear

				title={"In Game videos "}
				subTitle={"Some of our developed mechanics"}
				video={[Video, Video_1, Video_2]}
			>
			</ProjectThreeVideoLinear>

			<CustomFooter></CustomFooter>

		</div>

	);
}

export default KreativaBrian;
