import useMediaQuery from '@mui/material/useMediaQuery';

import ListItemText from '@mui/material/ListItemText';
import CheckListModule from '../../../Components/Components/CheckListModule'

import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PreviewIcon from '@mui/icons-material/Preview';


import NavBar from '../../Layout/NavBar.js'
import HeaderProject from '../../Layout/HeaderProject'
import ProjectThreeImagesLinear from '../../Layout/ProjectThreeImagesLinear'

import ProjectCheckList from '../../Layout/ProjectCheckList'
import CustomFooter from '../../Layout/CustomFooter.js'

//images
import Image_Header from '../../../img/Projects/Gaming/ANewLeaf/ANL_Illustrations01.jpg';
import Logo from '../../../img/Projects/Gaming/ANewLeaf/ANewLeafLogo.png';
import Company_Logo from '../../../img/Projects/Gaming/ANewLeaf/IronHeartStudiosLogo.png';



//2D Characters Images
import Image_Char2D_0 from '../../../img/Projects/Gaming/MitrilGames/PetCubosi01.jpg';
import Image_Char2D_1 from '../../../img/Projects/Gaming/MitrilGames/PetCubosi02.jpg';
import Image_Char2D_2 from '../../../img/Projects/Gaming/MitrilGames/PetCubosi03.jpg';

//3D Characters Images
import Image_Char3D_0 from '../../../img/Projects/Gaming/MitrilGames/CubePets-Robot_Front-Logo.jpg';
import Image_Char3D_1 from '../../../img/Projects/Gaming/MitrilGames/CubePets-Summer_Front-Logo.jpg';
import Image_Char3D_2 from '../../../img/Projects/Gaming/MitrilGames/CubePets-Winter_Front-Logo.jpg';

import Character3D from '../../Components3D/Character3D'
import MitrilGamesCharacter from '../../Components3D/Models3D/MitrilGamesCharacter'

function ANewLeaf({ children, ...props }) {

	const mobile = useMediaQuery('(max-width:600px)');

	const checkListFontSize = mobile ? '18px' : '22px'

	return (
		<div
			style={{
				background: "linear-gradient(270deg, rgba(253,252,251,0) 0%, rgba(249,246,243,0.45702030812324934) 50%, rgba(226,209,195,1) 100%)"
			}}
		>
			<NavBar></NavBar>
			<HeaderProject
				backgroundImage={Image_Header}
				logo={Logo}
				companyLogo={Company_Logo}
				titles={[
					"Mitril Games"
				]}

				subTitles={[
					"You wake up on the beach of a foreign island, unable to recall anything. Alone and equipped with only basic farming tools provided by the town residents, you aim to recover your memories. Complete quests and explore Omoide Island to unfold your story.",
					"We develop this game for Ironheart Studio. We develop the game in unity and we took care of mechanics, concept art, 3D art, shaders and coding"
				]}
			></HeaderProject>

			<ProjectCheckList
				checkListModules={[
	
					<CheckListModule
						title={"3D Art"}
						icon={<ThreeDRotationIcon style={{ fontSize: "35px" }}></ThreeDRotationIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Isnbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
						]}
					></CheckListModule>,
					<CheckListModule
						title={"2D Art"}
						icon={<PreviewIcon style={{ fontSize: "35px" }}></PreviewIcon>}
						leftItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Inbox" />
						]}

						rightItems={[
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Isnbox" />,
							<ListItemText primaryTypographyProps={{ fontSize: checkListFontSize }} primary="Idnbox" />,
						]}
					></CheckListModule>,
			

				]}

			></ProjectCheckList>

			<Character3D><MitrilGamesCharacter></MitrilGamesCharacter></Character3D>


			<ProjectThreeImagesLinear
				title={"2D Env Concepts"}
				subTitle={"Some of our 2D environments used in game for loading screens"}
				Images={[
					Image_Char2D_0,
					Image_Char2D_1,
					Image_Char2D_2
				]}
			></ProjectThreeImagesLinear>

			<ProjectThreeImagesLinear
				title={"2D Env Concepts"}
				subTitle={"Some of our 2D environments used in game for loading screens"}
				Images={[
					Image_Char3D_2,
					Image_Char3D_0,
					Image_Char3D_1
				]}
			></ProjectThreeImagesLinear>


			<CustomFooter></CustomFooter>

		</div>

	);
}

export default ANewLeaf;
