import {  useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { InView } from 'react-intersection-observer'
import { useSprings, a } from 'react-spring'
import useMediaQuery from '@mui/material/useMediaQuery';

import Character3D from '../Components3D/Character3D'
import GirlCharacter from '../Components3D/Models3D/GirlCharacter'

function HomePage3DCharacter() {

	const mobile = useMediaQuery('(max-width:1000px)');
	const theme = useTheme();

	const [show, setShow] = useState(false)
	const [onTop, setOnTop] = useState(false)

	const onInViewChange = inview => {
		if (!show && inview && onTop)
			setShow(true);
	}

	const text = [...'The Ramble Studi0']
	const from = { transform: 'translate3d(0,0px,0)' }
	const to = show ? [{ transform: 'translate3d(0,-40px,0)' }, { transform: 'translate3d(0,0,0)' }] : from

	const base = {
		config: { mass: 5, tension: 2000, friction: 200, delay: 1000 },
		from: from,
		to: to,
	}

	const springs = useSprings(text.length, text.map((t, i) => ({ ...base, delay: 100 * i })))

	function listenToScroll() {
		if (window.scrollY > 0 && !onTop)
			setOnTop(true)

	}

	useEffect(() => {
		window.addEventListener('scroll', listenToScroll)

	});

	return (
		<Grid
			container
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
            }}
			
		>
			<div
				style={{
					
					width: mobile?"100vw":"600px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					mt={1}
					ml={10}
					mb={1}
					mr={10}
					textAlign="center"
				>
					<InView tag="div" onChange={onInViewChange}>
						<div
					
							style={{
								fontSize: mobile ? "48px" : "72px",
								color: theme.palette.secondary.main,
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							{springs.map((s, i) => {
								return (
									<a.span key={`char${i}`} style={s}>
										{text[i] === ' ' ? <>&nbsp;</> : text[i]}
									</a.span>
								)
							})}
						</div>
					</InView>

					<h2
						style={{
							fontSize: mobile ? "22px" : "28px",
							color: theme.palette.primary.main,
						}}
					>
						The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color.
					</h2>
				</Box>
			</div>

			<div
				style={{
					width: mobile ? "100vw" : "600px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Character3D>
					<GirlCharacter></GirlCharacter>
				</Character3D>
			</div>

		</Grid>

	);
}

export default HomePage3DCharacter;
