import React, {
	useState
} from "react";



function SwapImageOnHover({ children, ...props }) {


	const [hovered, setHover] = useState(false)


	return (
		<div
			onPointerOver={() => setHover(true)}
			onPointerLeave={() => setHover(false)}

			style={{
				backgroundImage: hovered ? `url(${props.backgroundImageReplaced})` : `url(${props.backgroundImage})`,
	
				backgroundRepeat: `no-repeat`,
				backgroundSize: "cover",
				backgroundPosition: "center bottom",
				width: "180px",
				height:"180px"
            }}

		>
			
		</div>


	);
}

export default SwapImageOnHover;
