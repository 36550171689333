
import Grid from '@mui/material/Grid';
function ProjectCheckList(props) {
	return (
		<Grid
			container
			style={{
				padding: 50,
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "start",

			}}
		>
			{props.checkListModules.map((item, index) =>
				<div key={index}>{item}</div>
			)}


		</Grid>

	);
}

export default ProjectCheckList;
